<template lang="pug">
  .permission-modal__table
    .permission-modal__table-head
      .permission-modal__table-row.permission-modal__table-row--header
        .card-subtitle.permission-modal__table-cell {{ 'base.read' | translate }}
        .card-subtitle.permission-modal__table-cell {{ 'base.write' | translate }}
        .card-subtitle.permission-modal__table-cell {{ 'base.change' | translate }}
        .card-subtitle.permission-modal__table-cell {{ 'base.delete' | translate }}
        .card-subtitle.permission-modal__table-cell {{ 'base.choose_all' | translate }}
    template(v-if="!loading")
      .permission-modal__table-body(v-if="permissionSection.length")
        permission-modal-table-row(
          v-for="user in permissionSection"
          :key="user.user_id"
          :user="user")
      p.permission-modal__table-empty(v-else) {{ 'messages.empty' | translate }}
    template(v-else)
      p.permission-modal__table-empty {{ 'Loading...' }}

</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import PermissionModalTableRow from './PermissionModalTableRow'

  export default {
    name: 'PermissionModalTable',

    components: {
      PermissionModalTableRow
    },

    data: () => ({
      loading: false
    }),

    computed: {
      ...mapGetters(['permissionSection'])
    },

    async mounted () {
      this.loading = true
      await this.fetchPermissionSectionById({ id: 1 })
      this.loading = false
    },

    methods: {
      ...mapActions(['fetchPermissionSectionById'])
    }
  }
</script>

<style lang="scss" scoped>
</style>
