<template lang="pug">
  v-card
    .permission-modal
      v-card-title
        h2.card-title {{ 'base.permission' | translate }}

      v-card-content
        .permission-modal__wrapper
          permission-modal-sidebar
          .permission-modal__body
            permission-modal-table

      //- v-divider
      //- v-card-actions
        .cols-x
          v-btn.ml-auto(
            :loading="loading"
            @click="onCreate") + Надати доступ

</template>

<script>
import PermissionModalSidebar from './PermissionModalSidebar'
import PermissionModalTable from './PermissionModalTable'

export default {
  name: 'CreateNewModal',

  components: {
    PermissionModalSidebar,
    PermissionModalTable
  }
}
</script>

<style lang="scss">
  @import '@/sass/abstracts/_variables.scss';

  .permission-modal {
    &__wrapper {
      display: flex;
    }

    &__sidebar-layout {
      width: 20%;
      padding-right: 1rem;
    }

    &__sidebar-title {
      margin-bottom: 4.5rem;
    }

    &__body {
      width: 80%;
    }

    &__table {
      display: grid;
      gap: 2rem 0;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
    }

    &__table-row {
      align-items: center;
      justify-items: center;
      border-bottom: .1rem solid $color-gray-light-2;

      &--header {
        display: grid;
        grid-template-columns: 12rem repeat(5, 1fr);
        grid-template-rows: 1fr;
        border-bottom: 0;

        .permission-modal__table-cell {

          &:first-child {
            grid-column: 2 / 3;
          }
        }
      }

      &--body {
        display: grid;
        grid-template-columns: 12rem repeat(5, 1fr);
        grid-auto-rows: 5rem;

        .permission-modal__table-cell {
          padding-left: 1rem;

          &:first-child {
            justify-self: start;
            padding-left: 0;

            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 12rem;

            font-size: 1.5rem;
          }
        }
      }
    }

    &__table-empty {
      font-size: 1.8rem;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &__item {
      user-select: none;
      position: relative;
      font-size: 1.5rem;
      line-height: 1.7rem;
      margin-bottom: 3rem;
      transition: .4s ease;

      &--active {
        color: $color-brand;
        transition: .4s ease;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: -2.5rem;
          transform: translateY(-50%);
          width: .3rem;
          height: 3.3rem;
          background-color: $color-brand;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        cursor: pointer;
        color: $color-brand;
      }
    }
  }
</style>
